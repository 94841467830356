.services {
    padding: 30px;
    text-align: center;
  }
  
  .services h2 {
    font-size: 2rem;
    font-weight: bold;
    color: black;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
    gap: 20px;
    padding: 50px;
  }
  
  .service-card-link {
    text-decoration: none; /* Remove underline from Link */
    color: inherit; /* Inherit text color */
  }
  
  .service-card {
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow transition */
  }
  
  .service-card:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Add pop-up shadow */
  }
  
  .service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
  }
  
  .service-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Slight tint */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 10px;
    transition: opacity 0.5s ease; /* Smooth fade-in transition */
  }
  
  .service-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  
  .service-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1); /* Darker overlay for hover */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    opacity: 0; /* Start fully transparent */
    transition: opacity 0.5s ease; /* Smooth fade-in transition */
  }
  
  .service-card:hover .service-hover-content {
    opacity: 1; /* Fade in on hover */
  }
  
  /* Mobile Responsiveness (Only for screens smaller than 768px) */
  @media (max-width: 768px) {
    .services {
      padding: 20px 10px; /* Reduce padding for smaller screens */
    }
  
    .services h2 {
      font-size: 1.5rem; /* Reduce font size for mobile */
      margin-bottom: 15px; /* Reduce margin for smaller screens */
    }
  
    .services-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
      gap: 15px; /* Reduce gap between cards */
      padding: 20px; /* Reduce padding for smaller screens */
    }
  
    .service-image {
      height: 150px; /* Reduce image height for mobile */
    }
  
    .service-title {
      font-size: 16px; /* Reduce font size for mobile */
    }
  
    .service-hover-content {
      font-size: 1rem; /* Reduce font size for mobile */
    }
  }
  
  /* Extra Small Screens (Only for screens smaller than 480px) */
  @media (max-width: 480px) {
    .services-grid {
      grid-template-columns: 1fr; /* 1 column on very small screens */
      gap: 10px; /* Further reduce gap between cards */
      padding: 10px; /* Further reduce padding for very small screens */
    }
  
    .service-image {
      height: 200px; /* Further reduce image height for very small screens */
    }
  
    .service-title {
      font-size: 18px; /* Further reduce font size for very small screens */
    }
  
    .service-hover-content {
      font-size: 0.9rem; /* Further reduce font size for very small screens */
    }
  }