/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: #333;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  /* Prevents unintended widths due to padding/margin */
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
}


h1,
h2,
h3 {
  margin: 0;
}

p {
  margin: 0;
}

:root {
  --desert-sand: #d8c5b4;
  --desert-gold: #fee6a9;
  --desert-night: #264653;
  --white: #ffffff;
  --dark-gray: #2a2a2a;
  --highlight: #fcb3a1;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }

  .hero h1 {
    font-size: 36px;
  }

  .service-cards {
    flex-direction: column;
    align-items: center;
  }

  .gallery-images {
    flex-direction: column;
    align-items: center;
  }
}