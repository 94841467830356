/* Reviews.css */
.reviews {
    background-color: white;
    padding: 30px 20px;
    text-align: center;
  }
  
  .reviews h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    color: black;
  }
  
  .review-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Horizontal grid layout */
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .review-card {
    background-color: var(--desert-sand);
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .review-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .profile-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
    object-fit: cover; /* Ensure the image maintains its aspect ratio */
  }
  
  .review-card p {
    color: var(--dark-gray);
    font-style: italic;
    font-size: 18px; /* Bigger font size */
    margin: 0;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%; /* Adjusted for mobile */
    width: 100%; /* Full width on mobile */
    position: relative;
    margin: 20px; /* Add margin for better spacing */
  }
  
  .modal-layout {
    display: flex;
    flex-direction: column; /* Stack content vertically on mobile */
    gap: 20px; /* Reduce gap for mobile */
    align-items: flex-start; /* Left-align items */
  }
  
  .modal-left {
    text-align: left; /* Left-align text */
  }
  
  .modal-profile-photo {
    width: 100px; /* Smaller photo for mobile */
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover; /* Ensure the image maintains its aspect ratio */
  }
  
  .modal-left h3 {
    color: black;
    margin-bottom: 10px;
    font-size: 22px; /* Smaller font size for mobile */
  }
  
  .modal-right {
    flex: 1;
    display: flex;
    align-items: flex-start; /* Left-align text */
    text-align: left; /* Left-align text */
  }
  
  .modal-right p {
    color: var(--dark-gray);
    font-style: italic;
    font-size: 16px; /* Smaller font size for mobile */
    margin: 0;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--desert-night);
  }
  
  .close-modal:hover {
    color: var(--desert-gold);
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .modal-content {
      padding: 15px; /* Reduce padding for mobile */
    }
  
    .modal-layout {
      gap: 15px; /* Reduce gap for mobile */
    }
  
    .modal-profile-photo {
      width: 80px; /* Smaller photo for mobile */
      height: 80px;
    }
  
    .modal-left h3 {
      font-size: 20px; /* Smaller font size for mobile */
    }
  
    .modal-right p {
      font-size: 14px; /* Smaller font size for mobile */
    }
  
    .close-modal {
      font-size: 20px; /* Smaller close button for mobile */
    }
  }