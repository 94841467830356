.image-slider {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.slides-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%;
}

.slide {
    position: relative;
    width: 100%;
    height: 100%;
}

.slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent black overlay */
    z-index: 1; /* Ensure the overlay is above the image */
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; /* Ensure the image is below the overlay */
    z-index: 0;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute; /* Change to absolute positioning */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; /* Higher than the tint */
}

.text-container {
    padding: 20px;
    border-radius: 8px;
    z-index: 3; /* Ensure text is above everything */
}

.left-arrow,
.right-arrow {
    cursor: pointer;
    z-index: 10;
}

.dots-container {
    z-index: 10;
}

.dot {
    cursor: pointer;
}