.hero-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    text-align: center;
    color: white;
    overflow: hidden;
    margin: 0;
    padding: 0;
    animation: fadeIn 1s ease-in-out; /* Smooth fade-in animation */
}

/* Black Background Section */
.black-background {
    background: 
        linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), /* Black tint fading towards the top */
        url('../../../public/images/contactbackground.jpg') no-repeat center center/cover; /* Replace with your image path */
    color: white;
    width: 100%;
    height: auto; /* Adjust height to fit content */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    padding: 20px; /* Add padding for spacing */
    box-sizing: border-box;
    position: relative;
    z-index: 2; /* Ensure the black background is above the navbar */
    animation: slideInFromLeft 1s ease-in-out; /* Smooth slide-in animation */
}

.contact-text {
    margin-top: 100px;
    margin-left: 75px; /* Keep the text aligned to the left */
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px; /* Add space below the text */
}

.icon-row {
    display: flex;
    gap: 50px; /* Space between icons */
    margin-left: 75px; /* Align icons with the text */
}

.icon-row a {
    color: white;
    font-size: 40px;
    transition: color 0.3s ease;
}

.icon-row a:hover {
    color: white; /* WhatsApp green */
}

/* White Box Section */
.white-box {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 65%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the box precisely */
    background-color: white;
    width: 50%; /* Adjust width as needed */
    height: 60%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    border-radius: 3px; /* Rounded corners */
    z-index: 3; /* Ensure the white box is above the black background */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Reduce space between title and content */
    color: black;
    animation: slideInFromRight 1s ease-in-out; /* Smooth slide-in animation */
}

.title {
    text-align: center;
    font-weight: bold;
    font-size: 42px; /* Increased font size */
    margin-bottom: 20px; /* Added more space below the title */
}

.content {
    display: flex;
    gap: 30px; /* Increased gap between left and right sides */
    position: relative; /* Required for the partition line */
}

.left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Reduce space between input boxes */
}

.input-box {
    padding: 8px; /* Reduce padding */
    border: none;
    background-color: #ececec;
    font-size: 14px; /* Reduce font size */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transition */
}

/* Hover effect for input boxes */
.input-box:hover {
    background-color: #c6c6c6; /* Lighter gray on hover */
}

/* Remove dark border on active input fields */
.input-box:focus {
    outline: none; /* Remove default focus outline */
    background-color: #c6c6c6; /* Lighter gray on focus */
}

/* Darker gray placeholder text */
.input-box::placeholder {
    color: #666; /* Darker gray placeholder */
}

.message-box {
    height: 80px; /* Reduce height for the message box */
    resize: none; /* Prevent resizing */
}

.send-button {
    background-color: black; /* WhatsApp green */
    color: white;
    border: none;
    padding: 16px 16px; /* Reduce padding */
    font-size: 14px; /* Reduce font size */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover transition */
}

.send-button:hover {
    background-color: var(--desert-gold); /* Darker green on hover */
    color: black;
}

.right-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    padding: 20px; /* Add padding for spacing */
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  }
  
  .right-side p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin: 0;
    text-align: left; /* Align text to the left */
  }
  
  .right-side p a {
    color: grey; /* Highlight links with a gold color */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Make links bold */
  }
  
  .right-side p a:hover {
    color :var(--desert-gold);
    text-decoration: underline; /* Add underline on hover */
  }

.info-item {
    display: flex;
    align-items: center;
    gap: 8px; /* Reduce space between icon and text */
}

.info-icon {
    font-size: 20px; /* Reduce icon size */
    color: var(--desert-gold); /* WhatsApp green */
}

.info-item span {
    font-size: 14px; /* Reduce text size */
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%) translateY(-50%); /* Slide in from the right while maintaining vertical centering */
        opacity: 0;
    }
    to {
        transform: translateX(-50%) translateY(-50%); /* Final centered position */
        opacity: 1;
    }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .hero-container {
        height: auto; /* Allow the container to grow with content */
        padding-bottom: 20px; /* Add padding at the bottom */
        flex-direction: column; /* Stack children vertically */
    }

    .black-background {
        height: 300px; /* Allow the background to grow with content */
        padding: 20px 10px; /* Adjust padding for smaller screens */
        align-items: center; /* Center align items on mobile */
    }

    .contact-text {
        margin-top: 100px; /* Reduce top margin */
        margin-left: 0; /* Remove left margin */
        font-size: 36px; /* Reduce font size for mobile */
        text-align: center; /* Center align text */
    }

    .icon-row {
        margin-left: 0; /* Remove left margin */
        justify-content: center; /* Center align icons */
        gap: 20px; /* Reduce gap between icons */
        margin-bottom: 20px; /* Add space below icons */
    }

    .white-box {
        position: relative; /* Change to relative for mobile */
        top: auto; /* Reset top positioning */
        left: auto; /* Reset left positioning */
        transform: none; /* Reset transform */
        width: 90%; /* Make the box wider on mobile */
        height: auto; /* Allow height to grow with content */
        margin: 30px auto; /* Center the box and remove top margin */
        padding: 15px; /* Reduce padding */
        animation: slideInFromBottom 1s ease-in-out; /* New animation for mobile */
    }

    .content {
        flex-direction: column; /* Stack left and right sides vertically */
        gap: 20px; /* Add gap between stacked sections */
    }

    .left-side,
    .right-side {
        width: 100%; /* Make both sides full width */
    }

    .input-box,
    .message-box {
        font-size: 16px; /* Increase font size for better readability */
    }

    .send-button {
        width: 100%; /* Make button full width */
        padding: 12px; /* Adjust padding */
        font-size: 16px; /* Increase font size */
    }

    .info-item span {
        font-size: 16px; /* Increase font size for better readability */
    }
}

/* New Animation for Mobile */
@keyframes slideInFromBottom {
    from {
        transform: translateY(100%); /* Slide in from the bottom */
        opacity: 0;
    }
    to {
        transform: translateY(0); /* Final position */
        opacity: 1;
    }
}