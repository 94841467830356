.footer {
    background-color: black; /* Match the background color */
    color: white;
    padding: 20px;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    text-align: center; /* Center text */
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
    gap: 10px; /* Space between text and icons */
    width: 100%; /* Ensure the container takes full width */
}

.footer-text {
    font-size: 16px;
    margin: 0; /* Remove default margin */
    color: white; /* Ensure text is white */
    text-align: center; /* Center the text */
}

.icon-row {
    display: flex;
    gap: 20px; /* Space between icons */
    align-items: center; /* Align icons vertically */
    justify-content: center; /* Center the icons horizontally */
}

.icon-row a {
    text-decoration: none; /* Remove underline */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Fixed size for icons */
    height: 30px; /* Fixed size for icons */
    border-radius: 50%; /* Circular shape */
    transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Phone icon default color */
.icon-row a[aria-label="Phone"] {
    color: green; /* Blue color for phone icon */
}

/* Email icon default color */
.icon-row a[aria-label="Email"] {
    color: #007bff; /* Red color for email icon */
}

/* WhatsApp icon default color */
.icon-row a[aria-label="WhatsApp"] {
    color: #25d366; /* WhatsApp green */
}

/* Instagram icon default color */
.icon-row a[aria-label="Instagram"] {
    color: #e4405f; /* Instagram pink */
}

/* Hover effect for all icons */
.icon-row a:hover {
    color: white; /* Change to white on hover */
    background-color: rgba(255, 255, 255, 0.1); /* Optional: Add a subtle background on hover */
}