.book-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.title {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  padding: 20px;
  color: black;
}

.section {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.section-header h2 {
  margin: 0;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tick {
  color: green;
  font-size: 20px;
}

.section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.section-content h3 {
  font-size: 26px;
  font-weight: bold;
}

.section-content.open {
  max-height: none; /* Remove fixed max-height */
  padding: 20px;
}

/* Default styles for mobile screens */
.packages-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(250px, 1fr)); /* 1 column on mobile */
  gap: 20px;
  padding-right: 10px;
}

/* Styles for larger screens (e.g., tablets and desktops) */
@media (min-width: 768px) {
  .packages-grid {
    grid-template-columns: repeat(3, minmax(250px, 1fr)); /* 3 columns on larger screens */
  }
}

/* Custom scrollbar for WebKit browsers (Chrome, Safari) */
.packages-grid::-webkit-scrollbar {
  width: 8px;
}

.packages-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.packages-grid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.packages-grid::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.package-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;
}

.package-card.selected {
  border: 2px solid black;
}

.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.package-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.package-card h4 {
  margin: 10px;
  color: #333;
  font-size: 18px;
}

.description {
  margin: 10px;
}

.description p {
  margin: 5px 0;
  color: #666;
  font-size: 14px;
}

.price {
  margin: 10px;
  font-weight: bold;
  color: var(--desert-gold);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.submit-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.submit-button:hover {
  box-shadow: 0 0 10px var(--desert-gold);
}

.additional-preferences {
  padding: 20px;
}

.additional-preferences p {
  margin: 10px 0;
  color: #333;
}

.additional-preferences input,
.additional-preferences select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}