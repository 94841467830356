.hero {
    position: relative;
    height: 100vh;
    background: 
        linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), /* Tint and fade-to-black effect */
        url('/public/images/background.jpg') no-repeat center center/cover; /* Background image */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    overflow: hidden;
    margin: 0;
    padding: 0;
    animation: fadeIn 1s ease-in-out;
}
.hero-content {
    position: relative;
    z-index: 1;
    padding: 20px 30px;
    border-radius: 10px;
    animation: slideUp 1.5s ease-in-out;
}

.hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero h2{
    font-size: 26px;
    margin-bottom: 20px;
}

.hero p {
    font-size: 18px;
    margin-bottom: 20px;
}

.hero-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 20px;
    color: white;
    background-color: var(--highlight);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-button:hover {
    background-color: var(--desert-gold);
    transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}