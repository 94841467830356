.services-hero-container {
    position: relative;
    width: 100%;
    height: 300px; /* Fixed height for the banner */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color: white;
    overflow: hidden;
    margin: 0;
    padding: 0;
    animation: fadeIn 1s ease-in-out; /* Smooth fade-in animation */
  }
  
  /* Black Background Section */
  .services-black-background {
    background: 
      linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), /* Black tint fading towards the top */
      url('../../../public/images/Atlantis.jpg') no-repeat center center/cover; /* Replace with your image path */
    width: 100%;
    height: 100%; /* Full height of the container */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative;
    z-index: 2; /* Ensure the black background is above the navbar */
    animation: fadeIn 1s ease-in-out; /* Smooth fade-in animation */
  }
  
  .services-hero-text {
    font-size: 34px;
    font-weight: bold;
    text-align: center; /* Center the text */
    animation: slideInFromLeft 1s ease-in-out; /* Smooth slide-in animation */
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }