.navbar {
    position: fixed;
    top: -100px; /* Start off-screen */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: white;
    z-index: 10;
    transition: top 0.3s ease, background-color 0.3s ease;
    box-sizing: border-box;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%); /* Fading background */
}

.navbar.visible {
    top: 0; /* Slide into view */
}

.navbar.hidden {
    top: -100px; /* Stay hidden */
}

/* Rest of your CSS remains the same */
.navbar-logo {
    display: flex;
    align-items: center;
    position: relative;
}

.navbar-logo img {
    width: 70px;
    height: 50px;
    margin-right: 10px;
}

.navbar-logo span {
    font-weight: bold;
    font-size: 18px;
    display: block;
    line-height: 1.2;
}

.navbar-logo span:first-child {
    transform: translateY(-50%);
}

.navbar-logo span:last-child {
    transform: translateY(50%);
    margin-left: 2px;
    white-space: nowrap;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 50px;
}

.navbar-links li {
    position: relative;
}

.navbar-links li:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 60%;
    background-color: rgba(255, 255, 255, 0.5);
}

.navbar-links li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
}

.navbar-links li a:hover {
    color: var(--highlight);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .navbar-logo {
      margin-bottom: 20px; /* Add margin between logo and links */
    }
  }