.different-services {
  padding: 20px;
  text-align: center;
  overflow: visible; /* Allow content to overflow naturally */
}

.service-categories {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.category-button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category-button.active {
  background-color: #333;
  color: white;
}

.category-button:hover {
  background-color: #333;
  color: white;
}

.service-packages {
  margin-top: 20px;
}

.packages-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Force 3 columns */
  gap: 20px;
  padding: 20px;
  overflow: visible; /* Ensure the grid does not create a scrollbar */
}

.package-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column; /* Make the card a flex container */
  /* Remove fixed height to allow cards to adjust based on content */
  min-height: 400px; /* Set a minimum height to ensure consistency */
}

.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.package-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 15px;
}

.package-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.package-card .price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

.package-card .description {
  font-size: 1rem;
  color: #666;
  text-align: left; /* Left-align the description */
  margin-bottom: 15px;
  flex-grow: 1; /* Allow description to grow and push button to the bottom */
  overflow-y: auto; /* Add scrollbar if description overflows */
}

.book-now-button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #333;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto; /* Push button to the bottom */
}

.book-now-button:hover {
  background-color: var(--desert-gold);
}

/* Mobile Responsiveness (Optional) */
@media (max-width: 768px) {
  .packages-grid {
    grid-template-columns: repeat(2, 1fr); /* Switch to 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  .packages-grid {
    grid-template-columns: 1fr; /* Switch to 1 column on very small screens */
  }
}

.disclaimer-box {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.disclaimer-box p {
  margin: 0;
}