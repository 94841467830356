/* Gallery.css */
.gallery {
    padding: 30px 20px;
    background-color: white;
    position: relative; /* Ensure the gradient covers the entire section */
}

.gallery h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    color: black; /* Change text color to white for better contrast */
    text-align: center;
    position: relative; /* Ensure the heading is above the gradient */
    z-index: 2; /* Ensure the heading is above the gradient */
}

.gallery-images {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    position: relative; /* Ensure the images are above the gradient */
    z-index: 2; /* Ensure the images are above the gradient */
}

.gallery-item {
    position: relative;
    width: 300px;
    height: 200px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.gallery-item:hover {
    transform: scale(1.05);
}

.gallery-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1; /* Ensure the tint is above the image but below the text */
}

.gallery-item:hover::after {
    opacity: 1;
}

.gallery-text {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for exact centering */
    color: var(--white);
    font-size: 16px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center; /* Center text alignment */
    z-index: 2; /* Ensure the text is above the tint */
}

.gallery-item:hover .gallery-text {
    opacity: 1;
}

/* Mobile Responsiveness (Only for screens smaller than 600px) */
@media (max-width: 600px) {
    .gallery {
        padding: 20px 10px; /* Reduce padding for smaller screens */
    }

    .gallery h2 {
        font-size: 28px; /* Reduce font size for mobile */
        margin-bottom: 20px; /* Reduce margin for smaller screens */
    }

    .gallery-images {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
        gap: 10px; /* Reduce gap between images */
    }

    .gallery-item {
        width: 100%; /* Full width of the grid cell */
        height: 150px; /* Reduce height for mobile */
    }

    .gallery-text {
        font-size: 14px; /* Reduce font size for mobile */
    }
}